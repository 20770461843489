import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';

const AboutUsModal = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>About Us: Every Dad Joke!</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {/* Your nicely formatted text about dad jokes */}
          Welcome to the world of dad jokes, where laughter reigns supreme and groans are just as valuable as chuckles. At "Every Dad Joke", we're on a mission to celebrate the timeless art of dad humor.<br/><br/>
          Picture this: a punchline that's so delightfully cringeworthy, it brings a smile to your face despite your best efforts to resist. That's the essence of a dad joke. It's a one-liner that dances on the fine line between cheesy and charming, delivering a dose of humor that's as classic as a well-worn pair of dad sneakers.<br/><br/>
          We've scoured the internet's vast expanse to curate a collection of dad jokes that spans generations. Our virtual trove is filled with witty zingers, punny wordplay, and clever quips that will tickle your funny bone while simultaneously causing your eyes to roll. It's an experience that transcends age, as the allure of a well-timed dad joke knows no boundaries.<br/><br/>
          From the dinner table to social gatherings, dad jokes are the secret weapon of humor aficionados. We're here to celebrate the groans, the giggles, and the collective eye-rolls that they elicit. Our passion for dad jokes is matched only by our commitment to sharing these gems with the world.<br/><br/>
          So join us in celebrating the art of dad jokes. Whether you're a seasoned pun-master or a novice in the world of humor, there's a dad joke waiting just for you. Explore our curated selection and get ready to embrace the joy of laughter that only a dad joke can deliver.<br/><br/>
          Remember, laughter is contagious, and with dad jokes, you're guaranteed to spread good vibes wherever you go. Dive into our collection, and let the pun begin!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AboutUsModal;





