import React from 'react';

const Blog = () => {

    const divStyle = {
        padding: '10px'  // Adjust the padding value as needed
      };

    return (
        <div style ={divStyle}>
            <center><h1>The Dad Joke Blog</h1></center>
            <center><b>2023-10-01 - The start of the blog</b></center>
            <span>
                Ah, the dad joke – it's not just a joke; it's THE joke. The undisputed king of one-liners, the pun that teeters on the edge of hilarity and cringe, the response that makes you go, "Did my dad seriously just say that?"<br/><br/>
                "Dad, I'm hungry."<br/>
                "Hi, Hungry - I'm dad."<br/>
                <br/>
                According to the ancient scrolls of Wikipedia (or at least, a Wikipedia article), the dad joke phenomenon hit its peak in 2017 on social media. Ah yes, the year of enlightenment when the #dadjokes hashtag reigned supreme on Twitter. The exact origin remains shrouded in mystery, much like the depths of the dad joke rabbit hole.
                <br/><br/>
                Even the esteemed scholars at Merriam-Webster acknowledged the power of the dad joke by officially adding "dad joke" to the dictionary in September 2019. A historic moment, indeed!
                <br/><br/>
                At EveryDadJoke, we tip our imaginary dad hats to the art of these peculiar puns. Sure, they might make you cringe a bit, but hey, we're all about spreading laughter here. Don't take it too seriously; we sure don't. These jokes are like the comfy slippers of humor – a little worn out, but oh-so-cozy.
                <br/><br/>
                The best dad jokes are the ones that elicit simultaneous laughter and groans, leaving your family contemplating whether disownment is a valid option.
                <br/><br/>
                The humor in dad jokes is like a fine cheese – simplistic, yet an acquired taste. It takes a true dad connoisseur to appreciate the immediate "daditude" of these jokes.
                <br/><br/>
                Men's Health once compiled the "200 best dad jokes of all time" in October 2021. Here at EveryDadJoke, we chuckled. 200 best dad jokes? That's just the warm-up! We've got a dad joke library that could rival the Great Library of Alexandria (well, in dad joke terms).
                <br/><br/>
                Dad jokes continue to thrive in the wild lands of the internet, and if you follow our blog, we might even share some dad joke gold from YouTube. Because, you know, a good dad joke is like a hearty meal – it's best when shared.
                <br/><br/>
                So, gather 'round, fellow dad joke enthusiasts! We hereby extend an invitation to you, yes YOU, to be a part of dad joke history. Share your own dad jokes, and let's keep the laughter rolling. After all, the more dad jokes, the merrier – just ask your dad; he's probably got a whole arsenal of them tucked away for special occasions.
                <br/><br/>
            </span>
        </div>
  );
};

export default Blog;