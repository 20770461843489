import React, { useEffect } from 'react'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import Blog from './components/Blog';

import SimplePageLayout from './components/SimplePageLayout'

export default function MyApp() {
  const location = useLocation()

  useEffect(() => {
    window.gtag('event', 'page_view', {
      page_path: location.pathname + location.search + location.hash,
      page_search: location.search,
      page_hash: location.hash,
    })
  }, [location])

  return (
    <React.StrictMode>
      <Routes>
        <Route
          path="/"
          element={
            <HelmetProvider>
              <SimplePageLayout />
            </HelmetProvider>
          }
        />
        <Route 
          path="/blog"
          element={
          <Blog />
          }
        />

      </Routes>
    </React.StrictMode>
  )
}
