import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';

const HistoryModal = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle><center>Version history - Everydadjoke.com</center></DialogTitle>
      <DialogContent>
        <DialogContentText>
          {/* Your nicely formatted text about dad jokes */}
          <b>2023-08-12</b> - The first version of everydadjoke is created.<br/>
          <b>2023-08-20</b> - Small updates to the front end.<br/>
          <b>2023-09-03</b> - Added totally unneccerary fader to the jokes.<br/>
          <b>2023-09-10</b> - Added the possibiltiy to vote for your favourite jokes.<br/>
          <b>2023-09-24</b> - Added "Submit your own joke!" Time for UGC!<br/>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HistoryModal;





