// components/SimplePageLayout.js

import React, { useState, useEffect, useRef } from 'react'

import { FacebookShareButton, TwitterShareButton } from 'react-share'
import { useSearchParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import styled, { keyframes } from 'styled-components'

import { Box, Typography, Button } from '@mui/material'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import CreateIcon from '@mui/icons-material/Create'
import FacebookIcon from '@mui/icons-material/Facebook'
import TwitterIcon from '@mui/icons-material/Twitter'
import Divider from '@mui/material/Divider'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import ThumbDownIcon from '@mui/icons-material/ThumbDown'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'
import NewReleasesIcon from '@mui/icons-material/NewReleases'
import ShareIcon from '@mui/icons-material/Share'
import { Link } from 'react-router-dom';
import Blog from './Blog';

import JokeSubmissionModal from './JokeSubmissionModal';

import AdsComponent from './AdsComponent'
import AboutUsModal from './AboutUsModal'
import CoffeeModal from './CoffeeModal'
import HistoryModal from './HistoryModal'
import Joke from './Joke'
import { config } from '../Constants'

const SimplePageLayout = () => {
  const handleClickOpen = () => {
    setSubmitOpen(true);
  };

const [showBlog, setShowBlog] = useState(false);
const handleShowBlog = () => {
  setShowBlog(true);
};

  const [searchParams] = useSearchParams()

  const id = searchParams.get('id')

  const [submitOpen, setSubmitOpen] = useState(false)

  const handleSubmitClose = () => {
    setSubmitOpen(false)
    fetchStats()
  }

  const [aboutUsOpen, setAboutUsOpen] = useState(false)

  const handleOpenAboutUs = () => {
    setAboutUsOpen(true)
  }

  const handleCloseAboutUs = () => {
    setAboutUsOpen(false)
  }

  const [CoffeeOpen, setCoffeeOpen] = useState(false)

  const handleOpenCoffee = () => {
    setCoffeeOpen(true)
  }

  const handleCloseCoffee = () => {
    setCoffeeOpen(false)
  }

  const [HistoryOpen, setHistoryOpen] = useState(false)

  const handleOpenHistory = () => {
    setHistoryOpen(true)
  }

  const handleCloseHistory = () => {
    setHistoryOpen(false)
  }

  const [showJoke, setShowJoke] = useState(false)
  const [joke, setJoke] = useState({})
  const [stats, setStats] = useState({})

  const flashRed = keyframes`
  0%, 50%, 100% {
    background-color: initial;
  }
  25%, 75% {
    background-color: pink;
  }
`

const FlashingButton = styled(Button)`
  &.flash-red {
    animation: ${flashRed} 1s;
  }
`
const Joke = styled.span`
/* Adjust the line height to achieve consistent spacing */
font-size: 3rem;
line-height: 1.2;

/* Media query for smaller screens */
@media (max-width: 768px) {
  font-size: 1.5rem; /* Adjust the font size for smaller screens */
  /* Adjust the line height for smaller screens */
  line-height: 1.5;
}
  `

  function fetchRandomJoke() {
    setShowJoke(false)
    fetch(config.url.API_URL + '/v1/jokes/random')
      .then((response) => response.json())
      .then((data) => {
        setJoke(data)
        setShowJoke(true)
      })
  }

  function fetchJoke(id) {
    setShowJoke(false)
    fetch(config.url.API_URL + '/v1/jokes/' + id)
      .then((response) => response.json())
      .then((data) => {
        setJoke(data)
        setShowJoke(true)
      })
  }

  function fetchStats() {
    fetch(config.url.API_URL + '/v1/stats')
      .then((response) => response.json())
      .then((data) => setStats(data))
  }

  const [upvotes, setUpvotes] = useState([]);

  // Load upvotes from localStorage on component mount
  useEffect(() => {
    const storedUpvotes = localStorage.getItem('upvotes');
    if (storedUpvotes) {
      setUpvotes(JSON.parse(storedUpvotes));
    }
  }, []);

  // Save upvotes to localStorage whenever the 'upvotes' state changes
  useEffect(() => {
    if (upvotes.length > 0) {
      localStorage.setItem('upvotes', JSON.stringify(upvotes));
    }
  }, [upvotes]);

  function upvote() {
    if (upvotes.includes(joke.id)) {
      buttonRefs[0].current.classList.add('flash-red'); // Add flash-red class
      removeFlashEffect(buttonRefs[0]);
      return
    }

    fetch(config.url.API_URL + '/v1/jokes/' + joke.id + '/upvote', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then((response) => {
      if (response.ok) {
        setJoke({
          ...joke,
          upvotes: joke.upvotes + 1
        });
        setUpvotes([...upvotes, joke.id]);
      }
    });
  }

  const [downvotes, setDownvotes] = useState([]);

  // Load downvotes from localStorage on component mount
  useEffect(() => {
    const storedDownvotes = localStorage.getItem('downvotes');
    if (storedDownvotes) {
      setDownvotes(JSON.parse(storedDownvotes));
    }
  }, []);

  // Save downvotes to localStorage whenever the 'downvotes' state changes
  useEffect(() => {
    if (downvotes.length > 0) {
      localStorage.setItem('downvotes', JSON.stringify(downvotes));
    }
  }, [downvotes]);

  function downvote() {
    if (downvotes.includes(joke.id)) {
      buttonRefs[2].current.classList.add('flash-red'); // Add flash-red class
      removeFlashEffect(buttonRefs[2]);
      return
    }

    fetch(config.url.API_URL + '/v1/jokes/' + joke.id + '/downvote', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then((response) => {
      if (response.ok) {
        setJoke({
          ...joke,
          downvotes: joke.downvotes + 1
        })
        setDownvotes([...downvotes, joke.id])
      }
    })
  }

  function removeFlashEffect(buttonRef) {
    if (buttonRef.current) {
      setTimeout(() => {
        buttonRef.current.classList.remove('flash-red');
      }, 1000);  // Remove the flashing effect after 1 second
    }
  }
  

  useEffect(() => {
    if (id === null) {
      fetchRandomJoke()
    } else {
      fetchJoke(id)
    }
  }, [id])

  useEffect(() => {
    fetchStats()
  }, [])

  const buttonRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  useEffect(() => {
    // Measure the widths of the buttons after they have rendered
    const buttonWidths = buttonRefs.map((ref) => ref.current.offsetWidth);

    const maxButtonWidth = Math.max(...buttonWidths);

    // Resize the buttons to have the maximum width
    buttonRefs.forEach((ref) => {
      if (ref.current) {
        ref.current.style.width = `${maxButtonWidth}px`;
      }
    });
  }, [buttonRefs]);

  return (
    <>
      <Helmet>
        <title>The Best Dad Jokes - Provided by Jokemachine!</title>
        <meta
          name="description"
          content="Laugh your ass off at the best dad jokes ever to be provided to the internet. Courtesy of the Jokemachine!"
        />
        <meta property="og:type" content="website" />
        <meta
          property="go:title"
          content="The Best Dad Jokes - Provided by Every Dad Joke!"
        />
        <meta
          property="og:description"
          content="The best source on the internet for dad jokes! Over 1000 jokes!"
        />
      </Helmet>

      <Box display="flex" flexDirection="column" minHeight="100vh" width="98%">
        {/* Top Bar with Icons */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding={2}
          bgcolor="#ffffff"
        >
          {/* Top Left Corner Icon */}
          <Box> 
            <a href="https://www.everydadjoke.com">
            <Box
            component="img"
            sx={{
            height: 50,
            width: 50,
            maxHeight: { xs: 233, md: 167 },
            maxWidth: { xs: 350, md: 250 },
          }}
          alt="Every Dad Joke!"
          src="/logo.png"
          />
          </a>
          </Box>
 
          {/* Center Content */}
          <Box textAlign="center">
            {/* ... (your existing center content) */}
          </Box>

          {/* Top Right Corner Icons */}
          <Box>
            <a
              href="https://www.buymeacoffee.com/everydadjoke"
              target="_blank"
              rel="noreferrer"
            >
              <Box
                component="img"
                sx={{
                  height: 50,
                  width: 50,
                  padding: 2,
                  maxHeight: { xs: 233, md: 167 },
                  maxWidth: { xs: 350, md: 250 },
                }}
                alt="Support us by buying us a coffe!"
                src="/coffee3.svg"
              />
            </a>

            <a
              href="https://www.facebook.com/profile.php?id=61550305876533"
              target="_blank"
              rel="noreferrer"
            >
              <Box
                component="img"
                sx={{
                  height: 50,
                  width: 50,
                  padding: 2,
                  maxHeight: { xs: 233, md: 167 },
                  maxWidth: { xs: 350, md: 250 },
                }}
                alt="Follow us on Facebook!"
                src="/facebook.svg"
              />
            </a>

            <a
              href="https://twitter.com/everydadjoke"
              target="_blank"
              rel="noreferrer"
            >
              <Box
                component="img"
                sx={{
                  height: 50,
                  width: 50,
                  padding: 2,
                  maxHeight: { xs: 233, md: 167 },
                  maxWidth: { xs: 350, md: 250 },
                }}
                alt="Follow us on Twitter/X."
                src="/twitter.svg"
              />
            </a>
          </Box>
        </Box>

        {/* Center Content and Right Banner */}
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexGrow={1}
          flexDirection={'column'}
        >
          <Box bgcolor="#fff" padding={2}>
            {/* Superfusklösning för att få centrerat...*/}
          </Box>

          <Box minHeight={250} display="flex" flexDirection="column">
            <Typography
              style={{
                textAlign: 'center',
                opacity: showJoke ? 1 : 0,
                transform: showJoke ? 'translateY(40px)' : 'translateY(0)', // Adjust the distance as needed
                transition: showJoke
                  ? 'opacity 1.0s ease, transform 1.0s ease'
                  : 'opacity 0s ease, transform 0s ease',
              }}
              className={`fade-in-joke ${joke ? 'fade-in-visible' : ''}`}
              variant="inherit"
              gutterBottom
            >
               {showBlog ? <Blog /> : <Joke>{joke.joke}</Joke>}  {/* Replace <Joke /> with your joke component */}
              
            </Typography>

            {/* Spacer to push the "Submitted by" text to the bottom */}
            <Box flex={1} />

            {/* Display joke submitter */}
            {joke.submitterName && (
              <Typography
                variant="caption"
                style={{
                  textAlign: 'right',
                  fontSize: '0.8rem',
                  verticalAlign: 'bottom',
                  display: 'block', }}
              >
                Joke submitted by - {joke.submitterName}
              </Typography>
            )}

          </Box>

          <Box textAlign="center">

            <FlashingButton ref={buttonRefs[0]} onClick={upvote} size="large" variant="outlined" sx={{ pt: 2, pb: 2, pr: 2, pl: 2, m: 2 }}>
              <ThumbUpIcon sx={{ pr: 2 }} />I liked this joke! {joke.upvotes > 0 && `(${joke.upvotes})`}
            </FlashingButton>

            {/*<Button style={{whiteSpace: 'nowrap'}} ref={buttonRefs[0]} onClick={upvote} size="large" variant="outlined" sx={{ pt: 2, pb: 2, pr: 2, pl: 2, m: 2 }}><ThumbUpIcon sx={{ pr: 2 }} />I liked this joke! {joke.upvotes > 0 && `(${joke.upvotes})`}</Button>*/}

            <Button
              style={{whiteSpace: 'nowrap'}}
              ref={buttonRefs[1]}
              onClick={fetchRandomJoke}
              size="large"
              sx={{ pt: 2, pb: 2, pr: 2, pl: 2, m: 2 }}
              variant="outlined"
            >
              <AutorenewIcon sx={{ pr: 2 }} />
              Give me a new joke!
            </Button>

            <FlashingButton ref={buttonRefs[2]} onClick={downvote} size="large" variant="outlined" sx={{ pt: 2, pb: 2, pr: 2, pl: 2, m: 2 }}>
              <ThumbDownIcon sx={{ pr: 2 }} />I hate this joke! {joke.downvotes > 0 && `(${joke.downvotes})`}
            </FlashingButton>

            {/*<Button style={{whiteSpace: 'nowrap'}} ref={buttonRefs[2]} onClick={downvote} size="large" variant="outlined" sx={{ pt: 2, pb: 2, pr: 2, pl: 2, m: 2 }}><ThumbDownIcon sx={{ pr: 2 }} />I hate this joke! {joke.downvotes > 0 && `(${joke.downvotes})`}</Button>*/}

            <Divider
              variant="string"
              width="fullwidth"
              textAlign="center"
              light
            />

            {/*<Button variant="outlined" size="large" sx={{ width: buttonWidth, pt: 2, pb: 2, pr: 2, pl: 2, m: 2 }}><EmojiEventsIcon sx={{ pr: 2 }} />100 Top rated jokes!</Button>
            */}
            <Button
              size="large"
              onClick={handleClickOpen}
              sx={{ pt: 2, pb: 2, pr: 2, pl: 2, m: 2 }}
              variant="outlined"
            >
              Submit own bad joke!
            </Button>

            <JokeSubmissionModal open={submitOpen} handleClose={handleSubmitClose} maxWidth="md" />

            {/*<Button size="large" variant="outlined" sx={{ width: buttonWidth, pt: 2, pb: 2, pr: 2, pl: 2, m: 2 }}><NewReleasesIcon sx={{ pr: 2 }} />100 newest jokes!</Button> */}

            <br />
            <FacebookShareButton
              url={'https://everydadjoke.com?id=' + joke.id}
              quote={joke.joke}
              hashtag={'#EveryDadJoke'}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: '10px',
                }}
              >
                <FacebookIcon />
                <span>Share on Facebook</span>
              </div>
            </FacebookShareButton>
            {/* <Button size="large" sx={{ pt: 2, pb: 2, pr: 2, pl: 2, m: 2 }}><InstagramIcon sx={{ pr: 2 }} />Share on Instagram</Button> */}

           <TwitterShareButton
              title={joke.joke}
              url={'https://everydadjoke.com?id=' + joke.id}
              hashtags={['EveryDadJoke', 'dadjokes']}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginLeft: '10px',
                }}
              >
                <TwitterIcon />
                <span>Share on Twitter</span>
              </div>
            </TwitterShareButton>

            {/*<Button size="small" sx={{ pt: 2, pb: 2, pr: 2, pl: 2, m: 2 }}><ShareIcon sx={{ pr: 2 }} />Share via URL!</Button>*/}

            {/*
            <Divider
              variant="string"
              width="fullwidth"
              textAlign="center"
              light
            />
              */}

            <br />
          </Box>

          {/* Right Banner */}
          <Box bgcolor="#ffffff" padding={2}>
            {/* Add your right banner content here */}
            <AdsComponent dataAdSlot="7259870550" />
          </Box>
        </Box>

        {/* Bottom Banner */}
        <Box width="100%" bgcolor="#ffffff" padding={2} textAlign="center">
          {/* Add your bottom banner content here */}
          {/* <img src="https://wiki.documentfoundation.org/images/b/bb/Banner_motif_728_90_blue.png"/> */}
        </Box>

        <Box
          bgcolor="#333333" // Dark grey background color
          color="white" // White text color
          padding={2}
          textAlign="center"
        >
          Welcome to the worlds biggest (and best) site about Dad Jokes! More
          than {stats.numberOfJokes} jokes and new jokes added daily!
          <br />
          <br />
          {/*
          <Button  onClick={handleShowBlog}
            color="inherit"
            sx={{ textTransform: 'none' }}          
          >
            Blog
          </Button>
          |
            */}
          <Button
            onClick={handleOpenAboutUs}
            color="inherit"
            sx={{ textTransform: 'none' }}
          >
            About Us
          </Button>
          |
          <Button
            onClick={handleOpenCoffee}
            color="inherit"
            sx={{ textTransform: 'none' }}
          >
            Support us with Coffee
          </Button>
          |
          <Button
            onClick={handleOpenHistory}
            color="inherit"
            sx={{ textTransform: 'none' }}
          >
            History
          </Button>
          <br />
          <br />
          © 2023 EveryDadJoke.com
          <br />
          <br />
        </Box>
        <AboutUsModal open={aboutUsOpen} onClose={handleCloseAboutUs} />
        <CoffeeModal open={CoffeeOpen} onClose={handleCloseCoffee} />
        <HistoryModal open={HistoryOpen} onClose={handleCloseHistory} />
      </Box>
    </>
  )
}

export default SimplePageLayout
