import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';

const CoffeeModal = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Support us with a coffee!</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {/* Your nicely formatted text about dad jokes */}
          Hey there! 👋 Are you a fan of those classic dad jokes that never fail to tickle your funny bone? We're thrilled to be the purveyors of good times and groans, delivering the finest dad jokes to brighten your day.<br/><br/>
          We believe that humor is a universal language that brings people together, and our mission is simple: to spread laughter far and wide. 🎉 But just like any finely tuned comedy act, we could use a little support to keep the jokes flowing.<br/><br/>
          Here's where you come in! If you've ever found yourself giggling at one of our pun-filled punchlines, why not consider showing your appreciation by buying us a coffee at buymeacoffee.com? ☕️ Your support is entirely voluntary and humble, just like our dad jokes!<br/><br/>
          By tossing a virtual coffee our way, you're not only fueling our caffeine cravings but also helping us continue sharing the joy of dad jokes with the world. Your gesture, no matter how small, helps us keep the laughter alive and the website running.<br/><br/>
          Remember, it's all about those spontaneous chuckles, eye-rolls, and moments of sheer silliness that make life a tad more delightful. So, if you've ever been caught in a fit of laughter by one of our quips, consider becoming a part of our journey and supporting us in our mission to bring a smile to faces worldwide.<br/><br/>
          Whether you choose to buy us a coffee or simply enjoy our jokes, we're grateful for your presence in our corner of the internet. Thank you for being a part of our dad joke community! <br/><br/>
          If you would like to buy us a coffe to support us visit our page at <a href="https://www.buymeacoffee.com/everydadjoke">https://www.buymeacoffee.com/everydadjoke</a>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CoffeeModal;





