// JokeSubmissionModal.js

import React, {useState} from 'react';
import ReCAPTCHA from "react-google-recaptcha";

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';

import { config } from '../Constants'

const recaptchaSiteKey = '6LeSqE0oAAAAAEAFD81nQoqJD5NyIceS_rBSY8aA'
const recaptchaRef = React.createRef()

const JokeSubmissionModal = ({ open, handleClose, maxWidth }) => {
  const [submitEnabled, setSubmitEnabled] = useState(false)

  const onCaptchaSuccess = () => {
    setSubmitEnabled(true)
  }

  const [joke, setJoke] = useState("")
  const [submitterName, setSubmitterName] = useState("")
  const [submitterEmail, setSubmitterEmail] = useState("")

  const onJokeChange = (event) => {
    setJoke(event.target.value);
  };

  const onSubmitterNameChange = (event) => {
    setSubmitterName(event.target.value);
  };

  const onSubmitterEmailChange = (event) => {
    setSubmitterEmail(event.target.value);
  };

  const onSubmit = () => {
    const body = {
      joke: joke,
      submitterName: submitterName,
      submitterEmail: submitterEmail
    }

    fetch(config.url.API_URL + '/v1/jokes?captcha=' + recaptchaRef.current.getValue(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
    })
    .then((response) => {
      if (response.ok) {
        handleClose()
      }
    })
  }

  return (
      <Dialog open={open} onClose={handleClose} maxWidth={maxWidth} fullWidth>
        <DialogTitle>Submit your own Joke!</DialogTitle>
        <DialogContent>
          <DialogContentText>Please type your own joke here:</DialogContentText>
          <TextField autoFocus={true} onChange={onJokeChange} value={joke} margin="dense" id="joke" label="joke goes here" type="text" fullWidth variant="standard" />
        </DialogContent>
        <DialogContent>
          <DialogContentText>Give us your funny name!</DialogContentText>
          <TextField onChange={onSubmitterNameChange} value={submitterName} margin="dense" id="name" label="funny name goes here" type="text" fullWidth variant="standard" />
        </DialogContent>
        <DialogContent>
          <DialogContentText>Provide your email address</DialogContentText>
          <TextField onChange={onSubmitterEmailChange} value={submitterEmail} margin="dense" id="email" label="email goes here" type="text" fullWidth variant="standard" />
        </DialogContent>
        <center>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={recaptchaSiteKey}
            onChange={onCaptchaSuccess}
          />
        </center>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={!submitEnabled} onClick={onSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>
  );
};

export default JokeSubmissionModal;
