const prod = {
    url: {
        API_URL: 'https://api.everydadjoke.com',
    }
}

const dev = {
    url: {
        API_URL: 'http://localhost:3001'
    }
}

export const config = process.env.REACT_APP_STAGE === 'local' ? dev : prod
